@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Quicksand';
  }
  body {
    @apply bg-white;
    font-family: 'Quicksand';
    -webkit-font-smoothing: subpixel-antialiased;
  }
  h1, .h1 {
    font-size: 3rem;
    line-height: 3.75rem;
    @apply font-light;
    letter-spacing: -0.06rem;

  }
  h2, .h2 {
    font-size: 2.25rem;
    line-height: 3rem;
    @apply font-light;
    letter-spacing: -0.045rem;
  }
  h3, .h3 {
    font-size: 1.75rem;
    line-height: 2.363rem;
    @apply font-semibold;
    letter-spacing: -0.035rem;
  }
  h4, .h4 {
    font-size: 1.375rem;
    line-height: 2.063rem;
    @apply font-semibold;
    letter-spacing: -0.028rem;
  }
  h5, .h5 {
    font-size: 1.125rem;
    line-height: 1.688rem;
    @apply font-normal uppercase;
    letter-spacing: .113rem;
  }
  p, .p, .p-default, .p-md {
    font-size: 1rem;
    line-height: 1.5rem;
    @apply font-normal;
  }
  a, .a {
    @apply underline text-blue3 decoration-blue3 hover:opacity-80;
  }
}

@layer utilities {

  /* Begin Button Utilities */
  .btn {
    @apply cursor-pointer border-2 px-6 my-2 focus:outline-none w-full rounded-full uppercase;
    height: 40px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    max-width: 200px;
  }

  .btn-primary {
    @apply btn;
    @apply bg-primary border-primary text-white;
  }

  .btn-secondary {
    @apply btn bg-secondary border-secondary text-white
  }

  .btn-secondary:hover {
    @apply btn-primary;
    transition: all 0.3s ease-out;
  }

  /* Begin Input Utilities */

  .input {
    width: 285px;
    height: 35px;
    left: 45.37px;
    top: 343.12px;
    padding: 0 10px;

    background: #FFFFFF;
    border-radius: 28px;
  }

  .input-label {
    width: 299.29px;
    height: 18px;
    left: 37.63px;
    top: 318.4px;

    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    margin-top: 20px;
    margin-bottom: 5px;
    padding: 0 10px;
    @apply sm:text-center md:text-left
  }

  select.input option {
    padding: 0 10px;
  }

  /* Misc Utilities */

  .box-shadow-light {
    box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.25);
  }

  .box-shadow-heavy {
    box-shadow: 4px 4px 8px -2px rgba(0,0,0,0.75);
  }

  .custom-container-width {
    width: 1152px;
  }

  .max-w-md-screen {
    max-width: 1150px;
  }

  br {
    @apply my-4
  }

  p {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .w-100 {
    width: 400px;
  }

  /* Begin Custom Component Styles */

  .payers-form { /* Also could be called leftWindow */
    padding: 20px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    @apply sm:w-100 md:w-100;
  }

  .small-image-slide {
    width: 400px;
    height: 180px;
  }

  .large-image-slide { 
    width: 736px;
    height: 452px;
  }

  .image-slide {
    @apply sm:small-image-slide md:large-image-slide;
  }

  .border-bottom-primary {
    border-bottom-width: 20px;
    @apply border-primary sm:w-100 md:custom-container-width;
  }

}
